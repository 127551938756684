var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      true
        ? _c("feather-icon", {
            attrs: {
              title: "Edit",
              icon: "Edit3Icon",
              svgClasses: "h-4 w-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.editRecord },
          })
        : _vm._e(),
      _c("feather-icon", {
        attrs: {
          title: "Copy",
          icon: "CopyIcon",
          svgClasses: "h-4 w-4 ml-2 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.duplicateImage },
      }),
      true
        ? _c("feather-icon", {
            attrs: {
              title: "Delete",
              icon: "TrashIcon",
              svgClasses: "h-4 w-4 ml-2 hover:text-danger cursor-pointer",
            },
            on: { click: _vm.deleteImage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }