var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c("div", { staticClass: "mt-6" }, [
        _c("h6", { staticClass: "text-primary" }, [
          _vm._v(_vm._s(_vm.settings.title)),
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col w-4/5" }, [
            _c(
              "div",
              { staticClass: "mt-4 ml-3" },
              [
                _c("vue-slider", {
                  attrs: {
                    value: _vm.sliderValue,
                    interval: _vm.dynamicInterval,
                    dragOnClick: true,
                    min: _vm.min,
                    max: _vm.max,
                    tooltip: "none",
                  },
                  on: { change: _vm.updateSettings },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-1/5 pl-0 widget-slider-input" }, [
            _c("input", {
              staticClass: "w-full",
              attrs: { type: "number", min: _vm.min, max: _vm.max },
              domProps: { value: _vm.sliderValue },
              on: {
                input: function ($event) {
                  return _vm.updateSettings($event.target.value)
                },
              },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }