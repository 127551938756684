var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        { staticClass: "widget-image-set" },
        [
          _c("h6", { staticClass: "text-primary mt-4 mb-4" }, [
            _vm._v(_vm._s(_vm.settings.title)),
          ]),
          _c("ag-grid-vue", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.value && _vm.value.length && _vm.value.length > 0,
                expression: "value && value.length && value.length > 0",
              },
            ],
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.rowData,
              gridOptions: _vm.gridOptions,
              rowDragManaged: true,
              animateRows: true,
              context: _vm.context,
              headerHeight: 0,
              rowHeight: _vm.rowHeight,
              domLayout: _vm.domLayout,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: { color: "primary" },
                  on: { click: _vm.onAddFile },
                },
                [_vm._v(_vm._s(_vm.addButtonCaption))]
              ),
            ],
            1
          ),
          _vm.showAddModal
            ? _c("travio-modal", { attrs: { width: "700", height: "520" } }, [
                _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v(_vm._s("Add " + _vm.modalTitle)),
                ]),
                _c(
                  "div",
                  { attrs: { slot: "body" }, slot: "body" },
                  _vm._l(_vm.formFieldSettings, function (formField, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          formField.component,
                          _vm._b(
                            {
                              tag: "component",
                              model: {
                                value: _vm.newFile[formField.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.newFile, formField.key, $$v)
                                },
                                expression: "newFile[formField.key]",
                              },
                            },
                            "component",
                            {
                              settings: formField.settings,
                              applicationId: _vm.applicationId,
                              allValues: _vm.isBlockTypeFormBuilder
                                ? _vm.newFile
                                : _vm.allValues,
                            },
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-wrap justify-center",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: { click: _vm.onSaveAdd },
                      },
                      [_vm._v("Save")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-2",
                        attrs: { color: "danger", type: "filled" },
                        on: { click: _vm.onCancelAdd },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.showEditModal
            ? _c("travio-modal", { attrs: { width: "700", height: "520" } }, [
                _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v(_vm._s("Edit " + _vm.modalTitle)),
                ]),
                _c(
                  "div",
                  { attrs: { slot: "body" }, slot: "body" },
                  _vm._l(_vm.formFieldSettings, function (formField, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          formField.component,
                          _vm._b(
                            {
                              tag: "component",
                              model: {
                                value: _vm.newFile[formField.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.newFile, formField.key, $$v)
                                },
                                expression: "newFile[formField.key]",
                              },
                            },
                            "component",
                            {
                              settings: formField.settings,
                              applicationId: _vm.applicationId,
                              allValues: _vm.isBlockTypeFormBuilder
                                ? _vm.newFile
                                : _vm.allValues,
                              widgetUrl: _vm.widgetUrl,
                              widgetInstanceId: _vm.widgetInstanceId,
                            },
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-wrap justify-center",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: { click: _vm.onSaveEdit },
                      },
                      [_vm._v("Save")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-2",
                        attrs: { color: "danger", type: "filled" },
                        on: { click: _vm.onCancelEdit },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }