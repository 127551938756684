var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "autocomplete-item p-3" }, [
    _c("div", { staticClass: "flex items-center justify-start" }, [
      _c(
        "div",
        { staticClass: "px-2 mr-2" },
        [
          _c("feather-icon", {
            attrs: { icon: _vm.item.icon, "svg-classes": "w-7 h-7" },
          }),
        ],
        1
      ),
      _c("div", [
        _c("div", [_vm._v(_vm._s(_vm.item && _vm.item.title))]),
        _c("div", { staticClass: "text-sm text-muted" }, [
          _vm._v(_vm._s(_vm.item && _vm.item.subtitle)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }