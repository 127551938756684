var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center" },
        [
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.params.data.fileName)),
          ]),
          _c("feather-icon", {
            staticClass: "mt-2",
            attrs: {
              title: "Edit",
              icon: "Edit3Icon",
              svgClasses: "h-5 w-5 ml-4 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.onEdit },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "file-manager-file-description my-3" }, [
        _vm._v(_vm._s(_vm.params.data.fileDescription)),
      ]),
      _vm._l(_vm.keywordList, function (keyword, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "vs-chip",
              {
                staticClass: "ag-grid-cell-chip mt-2 mr-2",
                attrs: { color: "success" },
              },
              [_c("span", [_vm._v(_vm._s(keyword))])]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }