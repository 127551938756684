var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c("div", { staticClass: "mt-8 mb-2" }, [
        _c("h6", { staticClass: "text-primary mb-2" }, [
          _vm._v(_vm._s(_vm.settings.title)),
        ]),
        _c("label", { staticClass: "text-sm" }, [
          _vm._v(_vm._s(_vm.settings.description || "")),
        ]),
        _vm.settings.format === "date"
          ? _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("datetime", {
                  attrs: {
                    type: "date",
                    title: _vm.settings.title,
                    "input-class": "v-datetimepickertxt",
                    format: "yyyy-MM-dd",
                    clearable: "",
                  },
                  on: { input: _vm.updateSettings },
                  model: {
                    value: _vm.dateModel,
                    callback: function ($$v) {
                      _vm.dateModel = $$v
                    },
                    expression: "dateModel",
                  },
                }),
              ],
              1
            )
          : _vm.settings.format === "time"
          ? _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("datetime", {
                  attrs: {
                    type: "time",
                    title: _vm.settings.title,
                    "input-class": "v-datetimepickertxt",
                    format: "HH:mm",
                    "use12-hour": "",
                    clearable: "",
                  },
                  on: { input: _vm.updateSettings },
                  model: {
                    value: _vm.dateModel,
                    callback: function ($$v) {
                      _vm.dateModel = $$v
                    },
                    expression: "dateModel",
                  },
                }),
              ],
              1
            )
          : _vm.settings.format === "date-time"
          ? _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("datetime", {
                  attrs: {
                    type: "datetime",
                    title: _vm.settings.title,
                    "input-class": "v-datetimepickertxt",
                    format: "yyyy-MM-dd HH:mm",
                    "use12-hour": "",
                    clearable: "",
                  },
                  on: { input: _vm.updateSettings },
                  model: {
                    value: _vm.dateModel,
                    callback: function ($$v) {
                      _vm.dateModel = $$v
                    },
                    expression: "dateModel",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }