var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-1/4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:50",
                    expression: "'required|max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  placeholder: "Give your widget a name...",
                  name: "Widget Name",
                },
                model: {
                  value: _vm.widgetInstanceName,
                  callback: function ($$v) {
                    _vm.widgetInstanceName = $$v
                  },
                  expression: "widgetInstanceName",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "vx-col w-1/4" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4",
                    attrs: { color: "danger" },
                    on: {
                      click: function ($event) {
                        _vm.showCancelDialog = true
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "vs-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.mode == "edit" ? _vm.saveWidget() : _vm.addWidget()
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-1/4" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-center" },
              [
                _c("vs-button", {
                  staticClass: "ml-2",
                  class: {
                    "active-button": _vm.previewClass === "preview-desktop",
                  },
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon-monitor",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.switchScreen("preview-desktop")
                    },
                  },
                }),
                _c("vs-button", {
                  staticClass: "ml-2",
                  class: {
                    "active-button": _vm.previewClass === "preview-tablet",
                  },
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon-tablet",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.switchScreen("preview-tablet")
                    },
                  },
                }),
                _c("vs-button", {
                  staticClass: "ml-2",
                  class: {
                    "active-button": _vm.previewClass === "preview-mobile",
                  },
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon-smartphone",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.switchScreen("preview-mobile")
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-1/4" }, [
            _c("div", { staticClass: "flex flex-wrap justify-end mt-3" }, [
              _c("h5", [_vm._v(_vm._s(this.widgetName))]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        this.mode == "add" && !this.templateSelected && this.templates
          ? _c(
              "div",
              { staticClass: "vx-col w-1/4 pl-4 pr-0" },
              [
                _c(
                  "vx-card",
                  {
                    staticStyle: {
                      "border-top-right-radius": "0px",
                      "border-bottom-right-radius": "0px",
                    },
                  },
                  [
                    _c(
                      "vue-custom-scrollbar",
                      {
                        staticClass: "fixed-height-widget-templates",
                        attrs: {
                          settings: {
                            suppressScrollY: false,
                            suppressScrollX: true,
                            wheelPropagation: false,
                          },
                        },
                      },
                      [
                        _c("application-widget-templates", {
                          attrs: { templates: _vm.templates },
                          on: {
                            continueWithTemplate:
                              _vm.handleContinueWithTemplate,
                            templateSelected: _vm.handleTemplateSelected,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "widget-editor-tab-container vx-col w-1/4 pr-0" },
              [
                _c(
                  "vx-card",
                  [
                    _vm.hasLoaded
                      ? _c(
                          "vs-tabs",
                          {
                            staticClass: "tabs-shadow-none",
                            attrs: { id: "profile-tabs" },
                            model: {
                              value: _vm.selectedTabIndex,
                              callback: function ($$v) {
                                _vm.selectedTabIndex = $$v
                              },
                              expression: "selectedTabIndex",
                            },
                          },
                          _vm._l(
                            Object.keys(_vm.categorizedSettings),
                            function (categorizedSetting, index) {
                              return _c(
                                "vs-tab",
                                {
                                  key: index,
                                  attrs: { label: categorizedSetting },
                                },
                                [
                                  _c(
                                    "vue-custom-scrollbar",
                                    {
                                      staticClass: "fixed-height-widget-editor",
                                      attrs: {
                                        settings: {
                                          suppressScrollY: false,
                                          suppressScrollX: true,
                                          wheelPropagation: false,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mt-0 ml-0 mr-5" },
                                        _vm._l(
                                          _vm.categorizedSettings[
                                            categorizedSetting
                                          ],
                                          function (formField, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "mt-2",
                                              },
                                              [
                                                _c(
                                                  formField.component,
                                                  _vm._b(
                                                    {
                                                      tag: "component",
                                                      model: {
                                                        value:
                                                          _vm.settingsData[
                                                            formField.key
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.settingsData,
                                                            formField.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "settingsData[formField.key]",
                                                      },
                                                    },
                                                    "component",
                                                    {
                                                      settings:
                                                        formField.settings,
                                                      applicationId:
                                                        _vm.applicationId,
                                                      allValues:
                                                        _vm.settingsData,
                                                      widgetUrl: _vm.widgetUrl,
                                                      widgetInstanceId:
                                                        _vm.widgetInstanceId,
                                                    },
                                                    false
                                                  )
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "vx-col w-3/4 preview-container pl-0" },
          [
            _vm.hasLoaded
              ? _c("vx-card", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap justify-center fixed-height-widget-preview",
                      staticStyle: {},
                    },
                    [
                      _c("iframe", {
                        ref: "previewFrame",
                        staticClass: "widgio-widget",
                        class: _vm.previewClass,
                        staticStyle: {
                          border: "0 solid #999",
                          margin: "0 auto",
                        },
                        attrs: {
                          id: "previewFrame",
                          src: _vm.iframe.src,
                          width: "100%",
                          height: "100%",
                        },
                        on: { load: _vm.loadConfig },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Are you sure you want to cancel?",
            "accept-text": "Yes, cancel changes",
            "cancel-text": "No, return to editor",
            "close-button": "false",
            active: _vm.showCancelDialog,
          },
          on: {
            cancel: _vm.cancelChanges,
            accept: _vm.cancel,
            "update:active": function ($event) {
              _vm.showCancelDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [_vm._v("All changes to this widget will be lost.")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }