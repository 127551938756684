var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h5", [_vm._v("Select a template to start with...")]),
      _c(
        "div",
        { staticClass: "vx-row match-height" },
        _vm._l(_vm.templates, function (template, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col w-1/2 mt-8" },
            [
              _c(
                "vx-card",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass: "template-card",
                  class: {
                    "selected-template": _vm.selectedTemplate === template,
                    "unselected-template": _vm.selectedTemplate !== template,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setSelectedTemplate(template)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: { src: template.imageUrl },
                      on: {
                        error: function ($event) {
                          return _vm.setAltImgForThumbnail(
                            $event,
                            template.title
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(_vm._s(template.title)),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "vs-button",
        {
          staticClass: "mt-4 mb-2 w-full",
          on: { click: _vm.continueWithTemplate },
        },
        [_vm._v("CONTINUE WITH THIS TEMPLATE")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }