var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c("div", { staticClass: "widget-input", class: _vm.marginClass }, [
        _c("h6", { staticClass: "text-primary" }, [
          _vm._v(_vm._s(_vm.settings.title)),
        ]),
        _c("div", { staticClass: "mb-2" }, [
          _vm.settings.description
            ? _c("span", {
                staticClass: "text-sm",
                domProps: { innerHTML: _vm._s(_vm.settings.description) },
              })
            : _vm._e(),
        ]),
        _vm.rowCount === 1
          ? _c("input", {
              staticClass: "w-full text-sm",
              attrs: { type: "text", maxlength: _vm.maxCharLength },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.updateSettings($event.target.value)
                },
              },
            })
          : _vm._e(),
        _vm.rowCount > 1
          ? _c("textarea", {
              staticClass: "w-full text-sm",
              class: _vm.marginClass,
              attrs: { maxlength: _vm.maxCharLength, rows: _vm.rowCount },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.updateSettings($event.target.value)
                },
              },
            })
          : _vm._e(),
        _vm.settings.maxLength
          ? _c("div", { staticClass: "flex flex-wrap justify-end mt-1" }, [
              _c("label", { staticClass: "text-sm" }, [
                _vm._v(_vm._s(_vm.remainingCharsText)),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }