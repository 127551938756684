var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        { staticClass: "widget-select-editor mt-8 mb-2" },
        [
          _c("h6", { staticClass: "text-primary mb-2" }, [
            _vm._v(_vm._s(_vm.settings.title)),
          ]),
          _c("div", { staticClass: "text-sm" }, [
            _vm._v(_vm._s(_vm.settings.description || "")),
          ]),
          _vm.allowMultiples
            ? _c("v-select", {
                staticClass: "mb-4",
                attrs: {
                  value: _vm.selectedValue,
                  options: _vm.options,
                  multiple: "",
                },
                on: { input: _vm.updateSettings },
              })
            : _vm.settings.format === "icon"
            ? _c("v-select", {
                attrs: {
                  options: _vm.options,
                  value: _vm.selectedValue,
                  "hide-details": "",
                  outlined: "",
                },
                on: { input: _vm.updateSettings },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selected-option",
                      fn: function ({ code }) {
                        return [
                          _c("div", [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(code) },
                            }),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function ({ code, label }) {
                        return [
                          _c("div", { domProps: { innerHTML: _vm._s(code) } }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1602919868
                ),
              })
            : _vm.settings.format === "font"
            ? [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap justify-end" },
                  [
                    _c("div", { staticClass: "mt-2 mb-2" }, [
                      _vm._v("Free Text"),
                    ]),
                    _c("vs-switch", {
                      staticClass: "mt-2 mb-2 ml-3",
                      on: { input: _vm.updateFreeTextOption },
                      model: {
                        value: _vm.isFreeText,
                        callback: function ($$v) {
                          _vm.isFreeText = $$v
                        },
                        expression: "isFreeText",
                      },
                    }),
                  ],
                  1
                ),
                !_vm.isFreeText
                  ? _c("v-select", {
                      staticClass: "mb-4",
                      attrs: {
                        value: _vm.selectedValue,
                        taggable: "",
                        options: _vm.fontFamilies,
                      },
                      on: { input: _vm.updateSettings },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selected-option",
                            fn: function ({ code }) {
                              return [
                                _c("span", { style: { fontFamily: code } }, [
                                  _vm._v(_vm._s(code)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "option",
                            fn: function ({ code, label }) {
                              return [
                                _c("span", { style: { fontFamily: code } }, [
                                  _vm._v(_vm._s(code)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3575384391
                      ),
                    })
                  : _vm._e(),
                _vm.isFreeText
                  ? _c("vs-input", {
                      staticClass: "w-full mt-2",
                      attrs: { value: _vm.freeTextValue },
                      on: { input: _vm.updateSettingsViaFreeText },
                    })
                  : _vm._e(),
              ]
            : _c("v-select", {
                staticClass: "mb-4",
                attrs: { value: _vm.selectedValue, options: _vm.options },
                on: { input: _vm.updateSettings },
              }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }