<template>
  <vx-card :title="widgetData.name" :subtitle="chartOptions.dateRangeLabel">
    <template slot="actions" v-if="!isReadonly">
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <small class="flex cursor-pointer">
          <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
        </small>
        
        <vs-dropdown-menu class="w-32">
          <vs-dropdown-item @click="handleEdit">Edit</vs-dropdown-item>
          <vs-dropdown-item @click="toggleGraph">{{ nextGraph }}</vs-dropdown-item>
          <vs-dropdown-item @click="handleRemove">Remove</vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

    </template>
    
    <wordcloud 
      v-if="graphType === 'Word Cloud'"
      :data="chartData"
      :rotate="rotate"
      spiral="archimedean"
      nameKey="name"
      valueKey="value"
      :wordClick="wordClickHandler"
    ></wordcloud>
    <top-search-location-table v-if="graphType === 'List'"  :chartData="chartData" :options="chartOptions"></top-search-location-table>
  </vx-card>
</template>

<script>
import wordcloud from "vue-wordcloud";
import TopSearchLocationTable from './TopSearchLocationTable.vue';

export default {
  name: 'top-search-location-widget',
  components: {
    wordcloud,
    TopSearchLocationTable,
  },
  props: {
    dashboardId: { type: Number, required: true},
    widgetData: { type: Object },
    chartData: { type: Array },
    chartOptions: { type: Object },
    isReadonly: { type: Boolean, required: true },
    applicationId: { type: Number},
    isCompanyDashboard: { type: Boolean, required: true}
  },
  data() {
    return {
      rotate: { from: 0, to: 0 },
      graphType: "Word Cloud",
      nextGraph : "List"
    }
  },
  mounted(){

  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    appStatsUrl () {
      return this.$store.state.ActiveApplicationStatsUrl;
    },
    appStatsUrlLink () {
      return this.$store.state.ActiveApplicationStatsUrlLink;
    },
  },
  methods: {
    handleEdit () {
      let routeParams = { 
        companyId: this.activeUserInfo.companyId,
        dashboardId: this.dashboardId,
        widgetInstanceId: this.widgetData.instanceId,
        componentEditor: 'top-search-location-editor',
        apiEndpoint: 'topSearchLocation',
        widgetId: this.widgetData.templateId,
        widgetName: this.widgetData.name,
        hidePresetRange: true
      }

      let routeName = 'company-dashboard-widget-edit'
      if (!this.isCompanyDashboard) {
        routeName = 'application-dashboard-widget-edit'
        routeParams.applicationId = this.applicationId
      }
      this.$router.push({ name: routeName , params: routeParams })
      
    },
    handleRemove () {
      this.$emit('deleteWidget', this.widgetData.instanceId)
    },
    wordClickHandler(name, value) {
      //alert(name);
    },
    toggleGraph () {
      let currentGraph = this.graphType;
      this.graphType = this.nextGraph;
      this.nextGraph = currentGraph;
    }
  }
  
}
</script>

<style>

</style>
