var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-3 file-list-container" },
    [
      _vm.params.data.fileType === "image"
        ? _c("img", {
            staticClass: "file-list-img",
            attrs: { src: _vm.params.data.previewUrl },
          })
        : _vm._e(),
      _vm.params.data.fileType === "generic"
        ? _c("feather-icon", {
            attrs: {
              title: "Document",
              icon: "FileIcon",
              svgClasses: "h-10 w-10 hover:text-primary cursor-pointer",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }