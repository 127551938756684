var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings
    ? _c("div", { staticClass: "widget-valued-unit-input mt-8 mb-2" }, [
        _c("h6", { staticClass: "text-primary mb-2" }, [
          _vm._v(_vm._s(_vm.settings.title)),
        ]),
        _c("label", { staticClass: "text-sm" }, [
          _vm._v(_vm._s(_vm.settings.description || "")),
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col w-1/2" }, [
            _c("input", {
              staticClass: "w-full",
              attrs: { type: "number" },
              domProps: { value: _vm.unitValue.value },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-1/2" },
            [
              _c("v-select", {
                attrs: {
                  value: _vm.selectedUnit,
                  options: _vm.options,
                  clearable: false,
                },
                on: { input: _vm.updateUnit },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }