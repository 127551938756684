var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        [
          _c("h6", { staticClass: "text-primary mb-2" }, [
            _vm._v(_vm._s(_vm.settings.title)),
          ]),
          _vm.settings.description
            ? _c("div", { staticClass: "text-sm mt-2 mb-2 rich-text-html" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.settings.description) },
                }),
              ])
            : _vm._e(),
          _c("quill-editor", {
            attrs: { value: _vm.value, options: _vm.editorOption },
            on: {
              change: _vm.onEditorChange,
              ready: function ($event) {
                return _vm.onEditorReady($event)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }