var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "travio-img-picker vx-row", class: _vm.marginClass },
    [
      _c("div", { staticClass: "vx-col w-full" }, [
        _c("h6", { staticClass: "text-primary mb-2" }, [
          _vm._v(_vm._s(_vm.settings.title)),
        ]),
      ]),
      _c("div", { staticClass: "vx-col w-full mb-2" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass: "travio-img-picker-container",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("img", {
                staticClass: "responsive card-img-top",
                attrs: { src: _vm.value },
              }),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/2" },
        [
          _c(
            "vs-button",
            {
              staticClass: "w-full",
              attrs: { color: "primary" },
              on: { click: _vm.selectImage },
            },
            [_vm._v("SELECT IMAGE")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2" },
        [
          _c(
            "vs-button",
            {
              staticClass: "w-full",
              attrs: { color: "warning" },
              on: { click: _vm.clearImage },
            },
            [_vm._v("CLEAR IMAGE")]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Image Manager",
            "class-content": "",
            active: _vm.showPickerPopup,
            "data-backdrop": "image-picker-popup",
            "data-keyboard": "true",
          },
          on: {
            "update:active": function ($event) {
              _vm.showPickerPopup = $event
            },
          },
        },
        [
          _c(
            "vs-tabs",
            {
              staticClass: "tabs-shadow-none",
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "vs-tab",
                { attrs: { label: "Image Gallery" } },
                [
                  _c(
                    "vue-custom-scrollbar",
                    {
                      staticClass: "fixed-height-popup-tab",
                      attrs: {
                        settings: {
                          suppressScrollY: false,
                          suppressScrollX: true,
                          wheelPropagation: false,
                        },
                      },
                    },
                    [
                      _c("vs-input", {
                        staticClass: "w-full mb-base",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-search",
                          placeholder: "Search",
                        },
                        model: {
                          value: _vm.searchTextImageGallery,
                          callback: function ($$v) {
                            _vm.searchTextImageGallery = $$v
                          },
                          expression: "searchTextImageGallery",
                        },
                      }),
                      _vm._l(_vm.systemImages, function (image, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "vx-row" },
                          [
                            _c("div", { staticClass: "vx-col w-3/4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap justify-start items-center",
                                },
                                [
                                  _c("vs-avatar", {
                                    attrs: {
                                      src: image.previewUrl,
                                      size: "120px",
                                    },
                                  }),
                                  _c("div", { staticClass: "ml-4" }, [
                                    _c("p", { staticClass: "font-semibold" }, [
                                      _vm._v(_vm._s(image.fileName)),
                                    ]),
                                    _c("small", [
                                      _vm._v(
                                        _vm._s(image.category1) +
                                          " / " +
                                          _vm._s(image.category2)
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "vx-col w-1/4" }, [
                              _c(
                                "div",
                                { staticClass: "mt-10 ml-16" },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectImageFromPicker(
                                            image.fullUrl
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Select")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "vs-tab",
                { attrs: { label: "Image By URL" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-8 mb-base",
                    attrs: { placeholder: "https://example.com/image.jpg" },
                    model: {
                      value: _vm.publicImageUrl,
                      callback: function ($$v) {
                        _vm.publicImageUrl = $$v
                      },
                      expression: "publicImageUrl",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right",
                          attrs: { type: "border" },
                          on: { click: _vm.closePopup },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right ml-2",
                          attrs: { color: "primary", type: "filled" },
                          on: { click: _vm.selectPublicImage },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-tab",
                { attrs: { label: "My Images" } },
                [
                  _c(
                    "vue-custom-scrollbar",
                    {
                      staticClass: "fixed-height-popup-tab",
                      attrs: {
                        settings: {
                          suppressScrollY: false,
                          suppressScrollX: true,
                          wheelPropagation: false,
                        },
                      },
                    },
                    [
                      _c("vs-input", {
                        staticClass: "w-full mb-base",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-search",
                          placeholder: "Search",
                        },
                        model: {
                          value: _vm.searchTextUserImages,
                          callback: function ($$v) {
                            _vm.searchTextUserImages = $$v
                          },
                          expression: "searchTextUserImages",
                        },
                      }),
                      _vm._l(_vm.userImages, function (image, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "vx-row" },
                          [
                            _c("div", { staticClass: "vx-col w-3/4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap justify-start items-center",
                                },
                                [
                                  _c("vs-avatar", {
                                    attrs: {
                                      src: image.previewUrl,
                                      size: "120px",
                                    },
                                  }),
                                  _c("div", { staticClass: "ml-4" }, [
                                    _c("p", { staticClass: "font-semibold" }, [
                                      _vm._v(_vm._s(image.fileName)),
                                    ]),
                                    _c("small", [
                                      _vm._v(_vm._s(image.fileSize) + " kb"),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "vx-col w-1/4" }, [
                              _c(
                                "div",
                                { staticClass: "mt-4 ml-16" },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectImageFromPicker(
                                            image.fullUrl
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Select")]
                                  ),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full mt-3",
                                      attrs: { color: "danger" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showDeleteImagePopup = true
                                          _vm.selectedFileKeyToRemove =
                                            image.fileKey
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-center" },
                        [
                          _c("input", {
                            ref: "uploadImpageInput",
                            attrs: {
                              type: "file",
                              multiple: "",
                              hidden: "",
                              id: "uploadImpageInput",
                            },
                            on: { change: _vm.handleFileUpload },
                          }),
                          _c(
                            "vs-button",
                            {
                              staticClass: "float-right ml-2",
                              attrs: { color: "primary", type: "filled" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.uploadImpageInput.click()
                                },
                              },
                            },
                            [_vm._v("Upload Image(s)")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              attrs: {
                title: "Delete Image",
                active: _vm.showDeleteImagePopup,
              },
              on: {
                "update:active": function ($event) {
                  _vm.showDeleteImagePopup = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("div", {}, [
                    _vm._v("Are you sure you want to delete this image?"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteImagePopup = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right ml-2",
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function ($event) {
                          _vm.removeImageFromPicker()
                          _vm.showDeleteImagePopup = false
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }