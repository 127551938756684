var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        { staticClass: "widget-input mt-6" },
        [
          _c("h6", { staticClass: "text-primary mb-2" }, [
            _vm._v(_vm._s(_vm.settings.title)),
          ]),
          _c("label", { staticClass: "text-sm" }, [
            _vm._v(_vm._s(_vm.settings.description || "")),
          ]),
          _c("vs-switch", {
            on: {
              change: function ($event) {
                return _vm.updateSettings($event.target.value)
              },
            },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }