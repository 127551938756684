var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask", style: { zIndex: _vm.zIndex } }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "modal-container",
            style: { width: _vm.width + "px" },
          },
          [
            _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "vue-custom-scrollbar",
                  {
                    staticClass: "fixed-height-modal",
                    style: _vm.modalBodyStyles,
                    attrs: {
                      settings: {
                        suppressScrollY: false,
                        suppressScrollX: true,
                        wheelPropagation: false,
                      },
                    },
                  },
                  [_vm._t("body")],
                  2
                ),
              ],
              1
            ),
            _c("vs-divider", { staticClass: "mb-0 mt-0" }),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }