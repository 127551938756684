var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        { staticClass: "file-manager vx-row", class: _vm.marginClass },
        [
          _vm.settings.format == "file" && _vm.fileName
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full mb-2",
                    attrs: { value: _vm.fileName, disabled: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.settings.format == "file" && _vm.fileName
            ? _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: { color: "primary" },
                      on: { click: _vm.selectImage },
                    },
                    [_vm._v("SELECT FILE")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.settings.format == "file" && _vm.fileName
            ? _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: { color: "warning" },
                      on: { click: _vm.clearImage },
                    },
                    [_vm._v("CLEAR FILE")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.settings.format == "file" && !_vm.fileName
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: { color: "primary" },
                      on: { click: _vm.selectImage },
                    },
                    [_vm._v("ADD FILE")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.settings.format == "image",
                  expression: "settings.format=='image'",
                },
              ],
              staticClass: "vx-col w-full",
            },
            [
              _c("h6", { staticClass: "text-primary mb-2" }, [
                _vm._v(_vm._s(_vm.settings.title)),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.settings.format == "image",
                  expression: "settings.format=='image'",
                },
              ],
              staticClass: "vx-col w-full mb-2",
            },
            [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  {
                    staticClass: "file-manager-container",
                    attrs: { slot: "no-body" },
                    slot: "no-body",
                  },
                  [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: { src: _vm.value },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.settings.format == "image",
                  expression: "settings.format=='image'",
                },
              ],
              staticClass: "vx-col w-1/2",
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: { color: "primary" },
                  on: { click: _vm.selectImage },
                },
                [_vm._v("SELECT IMAGE")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.settings.format == "image",
                  expression: "settings.format=='image'",
                },
              ],
              staticClass: "vx-col w-1/2",
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: { color: "warning" },
                  on: { click: _vm.clearImage },
                },
                [_vm._v("CLEAR IMAGE")]
              ),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              attrs: {
                fullscreen: true,
                title: "File Manager",
                "class-content": "file-manager",
                active: _vm.showPickerPopup,
                "data-backdrop": "image-picker-popup",
                "data-keyboard": "true",
              },
              on: {
                "update:active": function ($event) {
                  _vm.showPickerPopup = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editorIframe.loaded,
                      expression: "!editorIframe.loaded",
                    },
                  ],
                  staticClass: "vx-row",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full px-0" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full file-manager-search",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-search",
                          placeholder: "Search",
                        },
                        model: {
                          value: _vm.searchTextImageGallery,
                          callback: function ($$v) {
                            _vm.searchTextImageGallery = $$v
                          },
                          expression: "searchTextImageGallery",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editorIframe.loaded,
                      expression: "!editorIframe.loaded",
                    },
                  ],
                  staticClass: "vx-row",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/6 file-manager-sidebar" },
                    [
                      _c("input", {
                        ref: "uploadImpageInput",
                        attrs: {
                          type: "file",
                          multiple: "",
                          hidden: "",
                          id: "uploadImpageInput",
                        },
                        on: { change: _vm.handleFileUpload },
                      }),
                      _c(
                        "vs-button",
                        {
                          staticClass: "w-full mt-4 mb-3",
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.uploadImpageInput.click()
                            },
                          },
                        },
                        [_vm._v("Upload Files")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "w-full",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.publicImageUrl = ""
                              _vm.showImportFromWebsitePopup = true
                            },
                          },
                        },
                        [_vm._v("Import From Website")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-start mt-4 px-3 py-3 side-bar-item",
                          class: { active: _vm.activeTab == 0 },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab(0)
                            },
                          },
                        },
                        [
                          true
                            ? _c("feather-icon", {
                                attrs: {
                                  title: "Select",
                                  icon: "HomeIcon",
                                  svgClasses:
                                    "h-4 w-4 hover:text-primary cursor-pointer",
                                },
                              })
                            : _vm._e(),
                          _c("h6", { staticClass: "ml-2" }, [
                            _vm._v("Dashboard"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-start px-3 py-3 side-bar-item",
                          class: { active: _vm.activeTab == 1 },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab(1)
                            },
                          },
                        },
                        [
                          true
                            ? _c("feather-icon", {
                                attrs: {
                                  title: "Select",
                                  icon: "ImageIcon",
                                  svgClasses:
                                    "h-4 w-4 hover:text-primary cursor-pointer",
                                },
                              })
                            : _vm._e(),
                          _c("h6", { staticClass: "ml-2" }, [
                            _vm._v("My Images"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-start px-3 py-3 side-bar-item",
                          class: { active: _vm.activeTab == 2 },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab(2)
                            },
                          },
                        },
                        [
                          true
                            ? _c("feather-icon", {
                                attrs: {
                                  title: "Select",
                                  icon: "FileIcon",
                                  svgClasses:
                                    "h-4 w-4 hover:text-primary cursor-pointer",
                                },
                              })
                            : _vm._e(),
                          _c("h6", { staticClass: "ml-2" }, [
                            _vm._v("My Documents"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-5/6 file-manager-main-content" },
                    [
                      _c(
                        "vue-custom-scrollbar",
                        {
                          staticClass: "fixed-height-main-scroll-area",
                          attrs: {
                            settings: {
                              suppressScrollY: false,
                              suppressScrollX: true,
                              wheelPropagation: false,
                            },
                          },
                        },
                        [
                          _vm.activeTab == 0 && !_vm.searchTextImageGallery
                            ? _c("div", [
                                _c("h4", { staticClass: "mt-4" }, [
                                  _vm._v("Dashboard"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "mt-5" },
                                  [
                                    _c("travio-widget-file-manager-gallery", {
                                      attrs: {
                                        applicationId: _vm.applicationId,
                                        fileList: _vm.freeImages,
                                      },
                                      on: {
                                        onEditFileAttribute: function ($event) {
                                          return _vm.editFileAttribute($event)
                                        },
                                        onSelectedFile: function ($event) {
                                          return _vm.selectImageFromPicker(
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.showPickerPopup
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "file-manager-tab-content",
                                      },
                                      [
                                        _c(
                                          "travio-widget-file-manager-file-list",
                                          {
                                            attrs: {
                                              applicationId: _vm.applicationId,
                                              fileList: _vm.userRecentFiles,
                                              widgetUrl: _vm.widgetUrl,
                                              widgetInstanceId:
                                                _vm.widgetInstanceId,
                                            },
                                            on: {
                                              onEditFileAttribute: function (
                                                $event
                                              ) {
                                                return _vm.editFileAttribute(
                                                  $event
                                                )
                                              },
                                              onSelectedFile: function (
                                                $event
                                              ) {
                                                return _vm.selectImageFromPicker(
                                                  $event
                                                )
                                              },
                                              onDeleteFile: function ($event) {
                                                return _vm.confirmFileDelete(
                                                  $event
                                                )
                                              },
                                              onEditFile: function ($event) {
                                                return _vm.handleFileEdit(
                                                  $event
                                                )
                                              },
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.activeTab == 1 && !_vm.searchTextImageGallery
                            ? _c("div", [
                                _c("h4", { staticClass: "mt-4" }, [
                                  _vm._v("My Images"),
                                ]),
                                _vm.showPickerPopup
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "file-manager-tab-content",
                                      },
                                      [
                                        _c(
                                          "travio-widget-file-manager-file-list",
                                          {
                                            attrs: {
                                              applicationId: _vm.applicationId,
                                              fileList: _vm.userImages,
                                              widgetUrl: _vm.widgetUrl,
                                              widgetInstanceId:
                                                _vm.widgetInstanceId,
                                            },
                                            on: {
                                              onEditFileAttribute: function (
                                                $event
                                              ) {
                                                return _vm.editFileAttribute(
                                                  $event
                                                )
                                              },
                                              onSelectedFile: function (
                                                $event
                                              ) {
                                                return _vm.selectImageFromPicker(
                                                  $event
                                                )
                                              },
                                              onDeleteFile: function ($event) {
                                                return _vm.confirmFileDelete(
                                                  $event
                                                )
                                              },
                                              onEditFile: function ($event) {
                                                return _vm.handleFileEdit(
                                                  $event
                                                )
                                              },
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.activeTab == 2 && !_vm.searchTextImageGallery
                            ? _c("div", [
                                _c("h4", { staticClass: "mt-4" }, [
                                  _vm._v("My Documents"),
                                ]),
                                _vm.showPickerPopup
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "file-manager-tab-content",
                                      },
                                      [
                                        _c(
                                          "travio-widget-file-manager-file-list",
                                          {
                                            attrs: {
                                              applicationId: _vm.applicationId,
                                              fileList: _vm.userDocuments,
                                              widgetUrl: _vm.widgetUrl,
                                              widgetInstanceId:
                                                _vm.widgetInstanceId,
                                            },
                                            on: {
                                              onEditFileAttribute: function (
                                                $event
                                              ) {
                                                return _vm.editFileAttribute(
                                                  $event
                                                )
                                              },
                                              onSelectedFile: function (
                                                $event
                                              ) {
                                                return _vm.selectImageFromPicker(
                                                  $event
                                                )
                                              },
                                              onDeleteFile: function ($event) {
                                                return _vm.confirmFileDelete(
                                                  $event
                                                )
                                              },
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.searchTextImageGallery
                            ? _c("div", [
                                _c("h4", { staticClass: "mt-4" }, [
                                  _vm._v("Search Results"),
                                ]),
                                _vm.showPickerPopup
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "file-manager-tab-content",
                                      },
                                      [
                                        _c(
                                          "travio-widget-file-manager-file-list",
                                          {
                                            attrs: {
                                              applicationId: _vm.applicationId,
                                              fileList: _vm.searchedImages,
                                              widgetUrl: _vm.widgetUrl,
                                              widgetInstanceId:
                                                _vm.widgetInstanceId,
                                            },
                                            on: {
                                              onEditFileAttribute: function (
                                                $event
                                              ) {
                                                return _vm.editFileAttribute(
                                                  $event
                                                )
                                              },
                                              onSelectedFile: function (
                                                $event
                                              ) {
                                                return _vm.selectImageFromPicker(
                                                  $event
                                                )
                                              },
                                              onDeleteFile: function ($event) {
                                                return _vm.confirmFileDelete(
                                                  $event
                                                )
                                              },
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.editorIframe.loaded
                ? _c("div", { staticClass: "vx-row file-manager-editor" }, [
                    _c("iframe", {
                      ref: "previewFrame",
                      staticStyle: { border: "0 solid #999", margin: "0 auto" },
                      attrs: {
                        id: "previewFrame",
                        src: _vm.editorIframe.src,
                        width: "100%",
                        height: "100%",
                      },
                    }),
                  ])
                : _vm._e(),
              _c(
                "vs-popup",
                {
                  attrs: {
                    title: "Edit My File",
                    active: _vm.showFileEditorPopup,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.showFileEditorPopup = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { label: "Name" },
                          model: {
                            value: _vm.currentFileInEdit.fileName,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentFileInEdit, "fileName", $$v)
                            },
                            expression: "currentFileInEdit.fileName",
                          },
                        }),
                        _c("vs-input", {
                          staticClass: "w-full mt-4",
                          attrs: { label: "Description" },
                          model: {
                            value: _vm.currentFileInEdit.fileDescription,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentFileInEdit,
                                "fileDescription",
                                $$v
                              )
                            },
                            expression: "currentFileInEdit.fileDescription",
                          },
                        }),
                        _c("vs-input", {
                          staticClass: "w-full mt-4 mb-2",
                          attrs: { label: "Keywords" },
                          model: {
                            value: _vm.currentFileInEdit.keywords,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentFileInEdit, "keywords", $$v)
                            },
                            expression: "currentFileInEdit.keywords",
                          },
                        }),
                        _c("span", { staticClass: "text-sm mb-4" }, [
                          _vm._v(
                            "Please enter keywords in comma seperated format"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right",
                          attrs: { type: "border" },
                          on: {
                            click: function ($event) {
                              _vm.showFileEditorPopup = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right ml-2",
                          attrs: { color: "primary", type: "filled" },
                          on: { click: _vm.saveFileAttribute },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-popup",
                {
                  attrs: {
                    title: "Import From Website",
                    active: _vm.showImportFromWebsitePopup,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.showImportFromWebsitePopup = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c("label", [
                        _vm._v(
                          "To import an image or file from a remote website, just enter the URL of the file below:"
                        ),
                      ]),
                      _c("vs-input", {
                        staticClass: "w-full mt-8 mb-base",
                        attrs: {
                          placeholder:
                            "https://externalwebsite.com/filename.ext",
                        },
                        model: {
                          value: _vm.publicImageUrl,
                          callback: function ($$v) {
                            _vm.publicImageUrl = $$v
                          },
                          expression: "publicImageUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right",
                          attrs: { type: "border" },
                          on: {
                            click: function ($event) {
                              _vm.showImportFromWebsitePopup = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right ml-2",
                          attrs: { color: "primary", type: "filled" },
                          on: { click: _vm.selectPublicImage },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-popup",
                {
                  attrs: {
                    title: "Delete Image",
                    active: _vm.showDeleteFilePopup,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.showDeleteFilePopup = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
                    _c("div", { staticClass: "vx-col w-full" }, [
                      _c("div", {}, [
                        _vm._v(
                          "Are you sure you want to delete the file " +
                            _vm._s(_vm.selectedFileNameToRemove) +
                            "?"
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right",
                          attrs: { type: "border" },
                          on: {
                            click: function ($event) {
                              _vm.showDeleteFilePopup = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-right ml-2",
                          attrs: { color: "primary", type: "filled" },
                          on: { click: _vm.removeImageFromPicker },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }