var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.theFileList,
          gridOptions: _vm.gridOptions,
          rowSelection: _vm.rowSelection,
          pagination: false,
          paginationPageSize: _vm.paginationPageSize,
          suppressPaginationPanel: true,
          context: _vm.context,
          domLayout: _vm.domLayout,
        },
      }),
      _c("FsLightbox", {
        attrs: {
          toggler: _vm.fullScreenToggle,
          sources: _vm.fileUrls,
          slide: _vm.fullScreenCurrentIndex,
          type: "image",
          types: [null, null, "video"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }