var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        { staticClass: "widget-autocomplete", class: _vm.marginClass },
        [
          _c("h6", { staticClass: "text-primary" }, [
            _vm._v(_vm._s(_vm.settings.title)),
          ]),
          _c("div", { staticClass: "mb-2" }, [
            _vm.settings.description
              ? _c("span", {
                  staticClass: "text-sm",
                  domProps: { innerHTML: _vm._s(_vm.settings.description) },
                })
              : _vm._e(),
          ]),
          _c("v-autocomplete", {
            attrs: {
              items: _vm.items,
              "get-label": _vm.getLabel,
              "component-item": _vm.itemTemplate,
            },
            on: { "update-items": _vm.updateItems, input: _vm.updateSettings },
            model: {
              value: _vm.itemInput,
              callback: function ($$v) {
                _vm.itemInput = $$v
              },
              expression: "itemInput",
            },
          }),
          _vm.showItem
            ? _c("div", { staticClass: "vx-row mt-2 px-2 py-4" }, [
                _c("div", { staticClass: "vx-col w-5/6" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-start" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-2 mr-2" },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: _vm.item.icon,
                              "svg-classes": "w-7 h-7",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("div", [_vm._v(_vm._s(_vm.item && _vm.item.title))]),
                        _c("div", { staticClass: "text-sm text-muted" }, [
                          _vm._v(_vm._s(_vm.item && _vm.item.subtitle)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "vx-col w-1/6",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-2" },
                      [
                        _c("feather-icon", {
                          staticClass: "m-0 cursor-pointer",
                          attrs: { icon: "XCircleIcon" },
                          on: { click: _vm.clearSelection },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }