var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.settings && _vm.show
    ? _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-chevron-right",
                    "icon-after": "",
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showModal = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.settings.title))]
              ),
            ],
            1
          ),
          _vm.showModal
            ? _c("travio-modal", { attrs: { width: "800" } }, [
                _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v(_vm._s("Edit " + _vm.settings.title)),
                ]),
                _c(
                  "div",
                  { attrs: { slot: "body" }, slot: "body" },
                  _vm._l(_vm.formFieldSettings, function (formField, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          formField.component,
                          _vm._b(
                            {
                              tag: "component",
                              model: {
                                value: _vm.settingsData[formField.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.settingsData, formField.key, $$v)
                                },
                                expression: "settingsData[formField.key]",
                              },
                            },
                            "component",
                            {
                              settings: formField.settings,
                              applicationId: _vm.applicationId,
                              allValues: _vm.settingsData,
                            },
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-wrap justify-center",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("Save")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-2",
                        attrs: { color: "danger", type: "filled" },
                        on: { click: _vm.onCancel },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }